<template>
	<div style="background:#f5f5f5;padding-bottom: 30px;" :style="{height: containerHeight}">
		<qy-top-nav title="确认订单" />
		<div style="height: 12px;"></div>
		<!-- <div style="display: flex; align-items: center; background: #fff; padding:6px 0; ;color: #333;"
			@click="$router.push('/proxyStg')">
			<img src="../../assets/img/add.png" alt="" class="addimg"
				style="width: 20px;margin-right:2px;padding:10px 4px 10px 12px;" />
			<div style="font-size:16px;font-weight:bold;">
				<div >{{proxyStg.name}}({{proxyStg.address}})</div>
			</div>
		</div> -->
		
		<div style="background-color: #fff;color: #333;border-top: 1px solid #ededed;position:relative;padding: 12px;padding-left:60px;font-weight: bold;" @click="selectAddress">
			<img src="../../assets/img/add.png" alt="" class="addimg" style="width: 25px;position: absolute;left: 16px;top: 20px;"/>
			
			<div style="font-size:16px;font-weight:bold;">
				<span>{{address.name}} {{address.phone}}</span>					
				
				<div>{{(address.county||"")+" "+address.address}}</div>
			</div>
			<!-- <span><van-icon name="friends-o" />切换</span> -->
		</div>
		<div v-if="proxiedUser.userName" style="margin-top: 12px;font-size: 16px;font-weight: bold;background:#fff;padding:10px 14px;">
			<van-icon name="label" class="prt2" color="red" size="20"/><span style="color: red;margin-left: 6px;font-size: 20px;">{{proxiedUser.userName}}</span>
		</div>
		<div v-for="(order,orderIndex) in payOrder.orderList" :key="orderIndex"
			style="background: #fff; margin: 12px 0;">
			<div style="padding:12px;border-bottom: 1px solid #ededed;">
				<van-icon name="send-gift" class="prt2" color="red"></van-icon>
				<span style="margin:0 4px 0 2px;">{{order.shopName}}</span>
			</div>
			<div v-for="(item,goodsIndex) in order.goodsList" :key="goodsIndex"
				style="padding: 5px 0px; margin: 4px 10px; border-bottom: 1px #ededed solid;  border-radius: 5px;">

				<div style="display: flex;align-items: center;">
					<div style="width: 70px;height: 70px;;">
						<img style="width: 70px;height: 70px;border-radius: 5px;"
							:src="getImgUrl(item.goodsLogo)" />
					</div>
					<div style="padding-left: 20px;">
						<div class="van-multi-ellipsis--l2">{{item.goodsRemark}}</div>
						<div class="van-multi-ellipsis--l2" style="font-size: 13px;color:#999;margin:5px 0;">
							<span>{{item.param1Val}}</span>
							<span style="color: #333;"><van-icon name="cross" class="prt2" />{{item.goodsNum}}</span>
						</div>
						<div>
							<span class="" style="font-size:12px;color: red;">¥<span
									style="font-size:14px;">{{qy.formatMoney(item.wholesalePrice,"面议")}}</span></span>
						</div>
					</div>
				</div>
			</div>
		</div>


		<div style="margin:10px 5px; border-radius: 5px;background-color: #fff;font-size: 14px;">
			
			<div style="font-size: 13px;">

				<van-field v-model="remark" rows="2" autosize label="备注" type="textarea" maxlength="100"
					placeholder="请输入订单备注信息" show-word-limit label-width="45" />
			</div>
		</div>
		<van-submit-bar :price="payOrder.totalPrice*100" button-text="提交订单" @submit="onSubmit()"></van-submit-bar>

	</div>
</template>
<script type="text/javascript">
	import request from '@/utils/request';
	import {
		Toast
	} from 'vant';
	export default {
		name: "orderAdd",
		data() {
			return {
				checked: false,
				address: {
					id: '1',
					name: '未选择',
					phone: '',
					address: '没有添加地址'
				},
				proxyStg: {
					id: 0,
					name: "",
					address: "123"
				},
				payOrder: {
					orderList: [],
				},
				proxiedUser: {},
				orderList: [],
				remark: "",
				isInWxMini: false
			}
		},
		created() {
			this.proxiedUser = this.getProxiedUser();
			this.isInWxMiniPro((rs) => {
				this.isInWxMini = rs;
			});
			let proxyStg = this.storage.get("proxyStg");
			if (!proxyStg) {
				request.post("/api/proxyStg/latest").then((rs) => {
					if (rs.success) {
						this.proxyStg = rs.data;
						this.storage.set("proxyStg", JSON.stringify(rs.data));
					} else {
						Toast.fail(rs.msg);
					}
				});
			} else {
				this.proxyStg = JSON.parse(proxyStg);
			}
			let address = this.storage.get("selectAddress");
			if (address) {
				this.storage.remove("selectAddress");
				this.address = JSON.parse(address);
			} else {
				this.doPost("/api/consignee/default",rs => {
					if(rs.success){
						this.address = rs.data;
					}
				})
			}
			let goodsList = JSON.parse(this.storage.get("goodsList") || "[]");
			let totalPrice = 0;
			let orderList = [];
			let orderObj = {};
			for (let j = 0; j < goodsList.length; j++) {
				let goods = goodsList[j];
				totalPrice += goods.wholesalePrice * goods.goodsNum;
				if (orderObj.hasOwnProperty(goods.shopId + "")) {
					orderObj[goods.shopId + ""].push(goods);
				} else {
					orderObj[goods.shopId + ""] = [];
					orderObj[goods.shopId + ""].push(goods);
				}
			}
			if (orderObj) {
				Object.keys(orderObj).forEach(function(shopId) {
					orderList.push({
						"shopId": shopId,
						"shopName": orderObj[shopId][0]['shopName'] || "店铺",
						"goodsList": orderObj[shopId] || []
					});
				});
			}

			this.payOrder.totalPrice = totalPrice;
			this.payOrder.orderList = orderList;
		},
		computed: {},
		mounted() {
			this.containerHeight = document.documentElement.clientHeight - 50 + "px";
		},
		methods: {
			selectAddress() {
				this.$router.push("/address/select")
			},
			onSubmit() {

				if (!this.address.name || !this.address.phone) {
					Toast("请选择收货人!")
					return;
				}
				/* this.Dialog.confirm({
					message: "您当前选择城市仓为【" + this.proxyStg.name + "】,确认无误后,点击【确认】继续提交订单！"
				}).then(() => {
					this.doSubmit()
				}); */
				this.doSubmit()
			},
			doSubmit() {
				let that = this;
				let data = {
					consigneeName: this.address.name,
					consigneePhone: this.address.phone,
					consigneeAddress: this.address.address,
					proxyId: this.proxyStg.id,
					remark: this.remark,
					goodsList: []
				};
				this.payOrder.orderList.forEach(function(order) {
					order.goodsList.forEach(function(goods) {
						data.goodsList.push(JSON.stringify({
							goodsInstanceId: goods.goodsInstanceId,
							goodsNum: goods.goodsNum
						}));
					});

				});
				this.Toast.loading();
				request.post("/api/order/add", this.serialize(data)).then(rs => {
					this.Toast.clear();
					if (rs.success) {
						this.$router.push({
							path: "/orderDetail",
							replace: true,
							query: {
								orderId: rs.data,
								openPay: 1
							}
						})
					} else {
						return this.Toast(rs.msg)
					}
				});
				this.showPayPanel = false;
			}
		}
	}
</script>
<style type="text/css" scoped="">
	ul {
		font-size: 14px;
		margin-bottom: 0.1rem;
	}

	ul>li {
		text-align: center;
		padding: 5px 0px;
		margin: 0px 10px;
		border-bottom: 1px #ededed solid;
		line-height: 30px;
		border-radius: 5px;
	}

	ul>li.active {
		background-color: #f7f7f7;
		color: #333;
	}
</style>